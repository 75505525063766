import { Icolors, IcolorsPalette } from "./colors.models";

const colorPalette: IcolorsPalette = {
  white: "hsl(360, 100%, 100%)",
  black: "hsl(360, 0%, 0%)",
  outerSpace: {
    light: "hsl(200, 20%, 50%)",
    regular: "hsl(200, 20%, 20%)",
    dark: "hsl(200, 20%, 10%)",
  },
  radicalRed: {
    light: "hsl(350, 97%, 75%)",
    regular: "hsl(350, 97%, 60%)",
    dark: "hsl(350, 97%, 40%)",
  },
  Loblolly: {
    light: "hsl(200, 10%, 94%)",
    regular: "hsl(200, 10%, 80%)",
    dark: "hsl(200, 10%, 70%)",
  }
};

const colors: Icolors = {
  background: {
    gradient: {
      light: {
        from: colorPalette.white,
        to: colorPalette.Loblolly.dark,
      },
      dark: {
        from: colorPalette.outerSpace.light,
        to: colorPalette.outerSpace.regular
      },
    },
    dark: colorPalette.outerSpace.dark,
    light: colorPalette.white,
    red: colorPalette.radicalRed.regular,
  },
  text: {
    regular: colorPalette.outerSpace.regular,
    light: colorPalette.white
  },
  link: {
    regular: {
      default: colorPalette.outerSpace.regular,
      hover: colorPalette.radicalRed.regular
    },
    light: {
      default: colorPalette.white
    }
  },
  icon: {
    regular: {
      default: colorPalette.outerSpace.regular,
      hover: colorPalette.radicalRed.regular
    },
    light: {
      default: colorPalette.white,
      hover: colorPalette.white
    }
  },
  shadow: colorPalette.outerSpace.light,
};

export default colors;
