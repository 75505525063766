export enum PortfolioPages {
  OVERVIEW,
  CAROUSEL,
  ABOUTME
}

export interface IPortfolioPagesModel {
  showArtworkCarouselPage: boolean;
  showAboutMePage: boolean;
  showOverview: boolean;
}
