import { keyframes } from "styled-components";

export const crossAnimationTop = keyframes`
    0% {
        transform: translateY(-16px);
    }
    50% {
      transform: translateY(0);
    }
    100% {
        transform: rotate(45deg);
    }
`;

export const crossAnimationMiddle = keyframes`
    0% {
        opacity: 1;
    }
    50% {
        opacity: 1;
    }
    51% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
`;

export const crossAnimationBottom = keyframes`
    0% {
      transform-origin: 4px 50%;
      transform: translateY(16px) scaleX(.5);
    }
    50% {
      transform-origin: 4px 50%;
      transform: rotate(0) translateY(0) scaleX(.5);
    }
    51% {
      transform-origin: 50% 50%;
            transform: scaleX(1);
    }
    100% {
      transform-origin: 50% 50%;
      transform: rotate(-45deg) scaleX(1);
    }
`;
