import { IfontSize } from "./typography.models";

const FontSize: IfontSize = {
  mobile: {
    normal: "1rem",
    medium: "0.875rem",
    large: "1.125rem",
    larger: "1.375rem",
    huge: "2.25rem",
  },
  desktop: {
    normal: "1rem",
    large: "1.125rem",
    medium: "0.875rem",
    larger: "1.625rem",
    huge: "2.875rem",
  }
};

export default FontSize;
