import {pageCarouselOrientation} from '../../../artwork-carousel.models';
import {animationSettings} from '../../../../../../../styles/settings/animation.settings';
import {TimelineMax, TweenMax} from 'gsap';


export const enterTrackerAnimation = (id: string, delay: number) => {
	const tl = new TimelineMax();
	const elementId = `#${id}`;

	TweenMax.set(elementId, {
		css: {
			transform: 'scale(0)',
			opacity: 1,
		}
	});

	tl.to(
		elementId,
		animationSettings.speed.fast,
		{
			css: {
				transform: 'scale(1)',
				opacity: 1,
			}
		},
		0
	).delay(delay);

};

export const currentIntAnimation = (newInt: number, currentInteger: number, orientation: pageCarouselOrientation) => {
	const tl = new TimelineMax();
	const isNext = orientation === pageCarouselOrientation.NEXT;
	const isIntro = orientation === pageCarouselOrientation.INTRO;
	const currentIntId = `#int-${currentInteger}`;
	const newIntId = `#int-${newInt}`;


	if (!isIntro) {
		TweenMax.set(newIntId, {
			css: {
				transform: isNext ? 'translateY(-40px)' : 'translateY(40px)',
			}
		});

		tl.to(
			newIntId,
			animationSettings.speed.slowest,
			{
				css: {
					transform: 'translateY(0)',
				}
			},
			0
		);


		TweenMax.set(currentIntId, {
			css: {
				transform: 'translateY(0)',
			}
		});

		tl.to(
			currentIntId,
			animationSettings.speed.slowest,
			{
				css: {
					transform: isNext ? 'translateY(40px)' : 'translateY(-40px)',
				}
			},
			0
		);
	}
};
