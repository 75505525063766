import {keyframes} from "styled-components";

export const navigationOpenAnimation = keyframes`
    0% {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    }
    100% {
      clip-path: polygon(0 50%, 100% 50%, 100% 50%, 0 50%);
    }
`;
