import {IAnimationSettings} from './settings.models';

export const animationSettings: IAnimationSettings = {
  speed: {
    slowest: 1,
    slower: 0.7,
    slow: 0.5,
    medium: 0.4,
    fast: 0.3,
    faster: 0.2,
    fastest: 0.1,
  },
  delay: {
    shortest: 0.1,
    shorter: 0.2,
    short: 0.4,
    medium: 0.6,
    long: 0.8,
    longer: 1,
    longest: 2,
  },
  portfolio: {
    introDelay: {
      circleBackground: 0,
      artwork: 2,
      menuIcon: .5,
      logo: .5,
      overviewIcon: .5,
      artworkContent: 3,
      tracker: 3,
      nextPrev: .5,
    }
  }
};
