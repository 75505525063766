export enum pageCarouselOrientation {
  NEXT,
  PREV,
  INTRO,
  NONE,
}

export enum artworkClassname  {
  CENTERED = 'centered',
  END = 'end',
}

export interface PageCarouselModel extends IArtworkContentModel {
  artworks: IArtworkModel[];
  orientation: pageCarouselOrientation;
}

export interface IArtworkModel extends IArtworkProps, IBackgroundGradientModel, IArtworkContentModel {}

export interface IArtworkCarouselProps extends IBackgroundGradientModel, PageCarouselModel, IArtworkProps {
  currentArtworkIndex: number;
  delayArtwork?: number;
  delayBackgroundCircle?: number;
}

export interface IArtworkContentModel {
  title: string;
  social: {
    behanceLink?: string;
    dribbbleLink?: string;
    societySixLink?: string;
    instagramLink?: string;
  };
  delay?: number;
}

export interface ICircleBackgroundProps extends IBackgroundGradientModel {
  backgroundImage: string;
}

export interface IBackgroundGradientModel {
  gradientFrom: string;
  gradientTo: string;
}

export interface IArtworkProps {
  imageName: string;
  className?: artworkClassname;
}

export interface ITrackerProps {
  maxInteger: number;
  currentInteger: number;
  orientation: pageCarouselOrientation;
  delay?: number;
}

export interface ITrackerState {
  isVisible: boolean;
}

export interface IBrowserModel {
  browserName: string;
  browserVersion: string;
}
