import styled from 'styled-components';
import {multiplyBase} from '../../../../styles/mixins/calculate';
import fontType from '../../../../styles/typography/fonts';
import FontSize from '../../../../styles/typography/font-size';
import colors from '../../../../styles/colors/colors';
import ZIndexSettings from '../../../../styles/settings/z-index.settings';

export const AboutMe = styled.article`
	display: flex;
	justify-content: center;	
	flex-direction: column;
	text-align: left;
	max-width: ${multiplyBase(80)};
	justify-self: center;
	transform: translateY(-${multiplyBase(15)});
	z-index: ${ZIndexSettings.aboutMe};
	
	h1 {
	font-family: ${fontType.title};
	font-size: ${FontSize.desktop.huge};
		color: ${colors.text.regular};
	}
	
	p {
		font-family: ${fontType.paragraph};
	font-size: ${FontSize.desktop.large};
	line-height: ${multiplyBase(4)};
	color: ${colors.text.regular};
	}
`;

