import React, {useEffect, useState} from 'react';
// Components
import {CircleBackground} from './components/CircleBackground/CircleBackground';
import {ArtworkContent} from './components/ArtworkContent/ArtworkContent';
import {Artwork} from './components/Artwork/Artwork';
// Models
import {IArtworkCarouselProps} from './artwork-carousel.models';
// Styles
import * as S from './artwork-carousel.styles';
import {animationSettings} from '../../../../styles/settings/animation.settings';

export const ArtworkCarousel = (props: IArtworkCarouselProps) => {
	const {
		title,
		social,
		gradientFrom,
		gradientTo,
		imageName,
		orientation,
		artworks,
		delayArtwork,
		delayBackgroundCircle,
		className,
		currentArtworkIndex,
	} = props;

	const [isArtworkVisible, setArtworkVisibility] = useState(false);
	const [isCircleBackgroundVisible, setCircleBackgroundVisibility] = useState(false);

	useEffect(() => {
		setTimeout(() => {
			setArtworkVisibility(true);
		}, delayArtwork ? delayArtwork * 1000 : 0);

		setTimeout(() => {
			setCircleBackgroundVisibility(false);
		}, delayBackgroundCircle ? delayBackgroundCircle * 1000 : 0);
	}, [delayArtwork, delayBackgroundCircle]);

	return (
		<React.Fragment>
			<ArtworkContent title={title}
			                currentArtworkIndex={currentArtworkIndex}
			                orientation={orientation}
			                social={social}
			                artworks={artworks}
			                delay={animationSettings.portfolio.introDelay.artworkContent}/>
			<S.ArtworkCarousel>
				{isArtworkVisible && (
                <Artwork imageName={imageName}
                         className={className}
                         orientation={orientation}
                         artworks={artworks}/>
                         )}
				{isCircleBackgroundVisible && (
					<CircleBackground gradientFrom={gradientFrom}
					                  gradientTo={gradientTo}
					                  backgroundImage={imageName}/>
					                  )}
			</S.ArtworkCarousel>
		</React.Fragment>
	);
};
