import {artworkClassname, IArtworkModel} from './pages/ArtworkCarousel/artwork-carousel.models';

const gradients = {
	placeholder: {
		from: '#FFFFFF',
		to: '#FFFFFF',
	}
};

export const ArtworkContent: IArtworkModel[] = [
	{
		title: 'Mechanical Koi',
		imageName: 'mechanical-koi',
		className: artworkClassname.CENTERED,
		gradientFrom: gradients.placeholder.from,
		gradientTo: gradients.placeholder.to,
		social: {
			dribbbleLink: 'https://dribbble.com/shots/9526608-koi?utm_source=Clipboard_Shot&utm_campaign=RayRay&utm_content=koi&utm_medium=Social_Share',
		},
	},
	{
		title: 'Stained glass 001',
		imageName: 'stained-glass-001',
		className: artworkClassname.CENTERED,
		gradientFrom: gradients.placeholder.from,
		gradientTo: gradients.placeholder.to,
		social: {
			dribbbleLink: 'https://dribbble.com/shots/14517274-stainted-glass-001?utm_source=Clipboard_Shot&utm_campaign=RayRay&utm_content=stainted%20glass%20001&utm_medium=Social_Share',
		},
	},
	{
		title: 'Stained glass 002',
		imageName: 'stained-glass-002',
		className: artworkClassname.CENTERED,
		gradientFrom: gradients.placeholder.from,
		gradientTo: gradients.placeholder.to,
		social: {
			dribbbleLink: 'https://dribbble.com/shots/15056135-stainted-glass-002?utm_source=Clipboard_Shot&utm_campaign=RayRay&utm_content=stainted%20glass%20002&utm_medium=Social_Share',
		},
	},
	{
		title: 'Dragon',
		imageName: 'dragon',
		className: artworkClassname.CENTERED,
		gradientFrom: gradients.placeholder.from,
		gradientTo: gradients.placeholder.to,
		social: {
			dribbbleLink: 'https://dribbble.com/shots/15126174-dragon?utm_source=Clipboard_Shot&utm_campaign=RayRay&utm_content=dragon&utm_medium=Social_Share',
		},
	},
	{
		title: 'Reaper',
		imageName: 'reaper',
		className: artworkClassname.CENTERED,
		gradientFrom: gradients.placeholder.from,
		gradientTo: gradients.placeholder.to,
		social: {
			dribbbleLink: 'https://dribbble.com/shots/15167312-reaper?utm_source=Clipboard_Shot&utm_campaign=RayRay&utm_content=reaper&utm_medium=Social_Share',
		},
	},
	{
		title: 'Pharah',
		imageName: 'pharah',
		className: artworkClassname.CENTERED,
		gradientFrom: gradients.placeholder.from,
		gradientTo: gradients.placeholder.to,
		social: {
			dribbbleLink: 'https://dribbble.com/shots/15167304-pharah?utm_source=Clipboard_Shot&utm_campaign=RayRay&utm_content=pharah&utm_medium=Social_Share',
		},
	},
	{
		title: 'Torbjörn',
		imageName: 'torbjorn',
		className: artworkClassname.CENTERED,
		gradientFrom: gradients.placeholder.from,
		gradientTo: gradients.placeholder.to,
		social: {
			dribbbleLink: 'https://dribbble.com/shots/15167321-torbjorn?utm_source=Clipboard_Shot&utm_campaign=RayRay&utm_content=torbjorn&utm_medium=Social_Share',
		},
	},
	{
		title: 'Reinhardt',
		imageName: 'reinhardt',
		className: artworkClassname.CENTERED,
		gradientFrom: gradients.placeholder.from,
		gradientTo: gradients.placeholder.to,
		social: {
			dribbbleLink: 'https://dribbble.com/shots/15167333-reinhardt?utm_source=Clipboard_Shot&utm_campaign=RayRay&utm_content=reinhardt&utm_medium=Social_Share',
		},
	},
	{
		title: 'Capra Lupus',
		imageName: 'capra-lupus',
		className: artworkClassname.CENTERED,
		gradientFrom: gradients.placeholder.from,
		gradientTo: gradients.placeholder.to,
		social: {},
	},
	{
		title: 'Mask of hatred',
		imageName: 'hatred',
		className: artworkClassname.CENTERED,
		gradientFrom: gradients.placeholder.from,
		gradientTo: gradients.placeholder.to,
		social: {
			dribbbleLink: 'https://dribbble.com/shots/9685008-Kyuubi-mask-of-hatred?utm_source=Clipboard_Shot&utm_campaign=RayRay&utm_content=Kyuubi%20-%20mask%20of%20hatred&utm_medium=Social_Share',
		},
	},
	{
		title: 'Tengu',
		imageName: 'tengu',
		className: artworkClassname.CENTERED,
		gradientFrom: gradients.placeholder.from,
		gradientTo: gradients.placeholder.to,
		social: {
			dribbbleLink: 'https://dribbble.com/shots/6496683-Devimon?utm_source=Clipboard_Shot&utm_campaign=RayRay&utm_content=Devimon&utm_medium=Social_Share',
		},
	},
];
