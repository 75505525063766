import React, {Component} from 'react';
// Styles
import * as S from './tracker.styles';
// Interfaces
import {ITrackerProps, ITrackerState, pageCarouselOrientation} from '../../artwork-carousel.models';
// Animations
import {animationSettings} from '../../../../../../styles/settings/animation.settings';
import {currentIntAnimation, enterTrackerAnimation} from './animations/tracker.animation';

export class Tracker extends Component<ITrackerProps, ITrackerState> {

	constructor(props: ITrackerProps) {
		super(props);
		this.state = {isVisible: false};
	}

	componentDidMount() {
		setTimeout(() => {
			this.setState({
				isVisible: true,
			});

			// Animation on enter
			enterTrackerAnimation(`int-${this.props.currentInteger}`, 0);
			enterTrackerAnimation(`divider`, animationSettings.delay.shorter);
			enterTrackerAnimation(`max-integer`, animationSettings.delay.shortest);
		}, this.props.delay ? this.props.delay*1000 : 0)
	}

	componentDidUpdate(prevProps: Readonly<ITrackerProps>) {
		// Only trigger the tracker animation
		// if there is an actual change in the currentInt
		if (prevProps.currentInteger !== this.props.currentInteger) {
			if (this.props.orientation === pageCarouselOrientation.NEXT) {
				currentIntAnimation(
					this.props.currentInteger,
					prevProps.currentInteger,
					this.props.orientation);
			}

			if (this.props.orientation === pageCarouselOrientation.PREV) {
				currentIntAnimation(
					this.props.currentInteger,
					prevProps.currentInteger,
					this.props.orientation);
			}
		}
	}

	renderIntegers = () => {
		const allIntegers = [];
		for (let i = 0; i < this.props.maxInteger; i++) {
			allIntegers.push(<S.Integer key={i} id={"int-" + (i + 1)}>{i + 1}</S.Integer>)
		}
		return allIntegers;
	};

	render() {
		const {maxInteger} = this.props;
		const {isVisible} = this.state;

		if (!isVisible) return null;

		return (
			<S.Tracker>
				<S.CurrentIntegerWrapper>
					{this.renderIntegers()}
				</S.CurrentIntegerWrapper>
				<S.Divider id="divider"/>
				<S.MaxInteger id="max-integer">{maxInteger}</S.MaxInteger>
			</S.Tracker>
		);
	}
};
