import React from 'react';
import * as S from './society-six.styles';

export const SocietySixIcon = () => {
	return (
		<S.SocietySixIcon width="100%" height="100%" viewBox="0 0 26 26">
			<path d="M5.183,20.049C5.183,20.049 5.108,18.541 3.881,17.537C2.653,16.534 1.409,17.01 1.049,17.728C0.69,18.446 0.521,20.623 5.22,20.697C9.919,20.771 10.673,19.701 11.198,19.033C11.724,18.365 11.808,17.852 11.872,17.199C11.872,17.199 13.094,20.824 17.351,20.716C21.608,20.607 24.678,17.177 25.023,14.305C25.367,11.433 24.804,9.393 21.731,9.387C18.659,9.381 17.427,10.311 16.822,10.639C16.822,10.639 16.922,9.285 18.151,7.556C19.38,5.826 20.807,5.063 21.524,4.852C22.242,4.642 23.352,4.764 23.916,4.237C24.479,3.71 24.657,3.369 24.064,3.317C23.471,3.265 22.047,3.096 18.758,4.454C15.468,5.813 13.728,7.712 12.408,10.259C12.408,10.259 11.182,8.556 7.739,8.614C4.295,8.673 2.633,9.689 2.41,11.884C2.186,14.078 4.635,15.113 5.769,15.703C6.903,16.292 7.842,16.874 7.759,17.855C7.676,18.836 7.225,19.854 5.183,20.049ZM18.557,11.549C17.754,11.536 16.257,12.163 15.511,14.78C14.765,17.398 16.199,20.121 17.492,20.092C18.784,20.063 20.396,18.274 20.727,15.206C21.057,12.139 19.473,11.564 18.557,11.549ZM8.506,9.286C8.506,9.286 6.227,9.157 6.106,10.934C5.985,12.711 7.933,13.047 9,13.513C10.067,13.98 11.024,14.344 11.48,15.206C11.48,15.206 11.413,13.027 11.826,12C11.826,12 11.284,12.413 10.496,12.313C9.709,12.212 8.311,11.825 8.506,9.286Z" />
		</S.SocietySixIcon>
	);
};
