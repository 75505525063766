import { keyframes } from "styled-components";

export const initAnimationTop = keyframes`
    0% {
            transform: translateY(-16px) scaleX(0);
    }
    100% {
            transform: translateY(-16px) scaleX(1);
    }
`;

export const initAnimationMiddle = keyframes`
    0% {
    opacity: 1;
            transform: scaleX(0);
    }
    100% {
        opacity: 1;
            transform: scaleX(1);
    }
`;

export const initAnimationBottom = keyframes`
    0% {
            transform: translateY(16px) scaleX(0);
    }
    100% {
            transform: translateY(16px) scaleX(.5);
    }
`;
