import React from 'react';
import * as S from './about-me.styles';

export const AboutMe = () => {
	return (
		<S.AboutMe>
			<h1>Welcome to my site</h1>
			<p>My name is Raymond Sanders, a front-end developer that loves illustrating. I enjoy inventing and
				developing UI animations to help create products that people value.</p>
		</S.AboutMe>
	);
};
