import React from "react";

import * as S from "./prev-icon.styles";

export const PrevIcon = () => {

    // ViewBox of the svg
    const viewBox = '0 0 64 64';
    // StrokeWidth of the lines
    const strokeWidth = 5;

  return (
      <S.PrevIcon viewBox={viewBox}>
        <circle cx="32" cy="32" r="29" fill="none" strokeWidth={strokeWidth} />
        <g>
          <line className="bar" x1="24" y1="40" x2="24" y2="4" strokeWidth={strokeWidth} />
          <line className="arrow-bar--left" x1="25" y1="0" x2="9" y2="16" strokeWidth={strokeWidth} />
          <line className="arrow-bar--right" x1="23" y1="0" x2="39" y2="16" strokeWidth={strokeWidth} />
        </g>
      </S.PrevIcon>
  );
};
