import styled from 'styled-components';
// Settings
import { multiplyBase } from '../../../../styles/mixins/calculate';
import { animationSettings } from '../../../../styles/settings/animation.settings';
import { artworkThumbnailAnimation, artworkThumbnailRightAnimation } from './animations/artwork-overview.animations';
import { StyledArtworkThumbnailProps } from './artwork-overview.model';

const stagger = 300;

export const ArtworkOverview = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: ${multiplyBase(3)};
  align-content: start;
  overflow-y: auto;
  
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ArtworkThumbnail = styled.img<StyledArtworkThumbnailProps>`
  width: 100%;
  opacity: 0;
  animation-name: ${props => props.index % 2 === 0 ? artworkThumbnailAnimation : artworkThumbnailRightAnimation};
  animation-duration: ${animationSettings.speed.medium}s;
  animation-fill-mode: forwards;
  animation-delay: ${props => props.delay * stagger}ms;
`;
