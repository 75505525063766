import React from "react";
import * as S from "./background.styles";
import {IBackgroundProps} from "./background.model";


export const Background = (props: IBackgroundProps) => {
  return (
      <S.Background {...props} />
  );
};
