export enum BackgroundStatus  {
    ENTER,
    LEAVE
}

export interface IBackgroundProps {
    from: string;
    to: string;
    status: BackgroundStatus;
}
