import styled from "styled-components";
// Settings
import colors from '../../../../../../styles/colors/colors';
import fontType from '../../../../../../styles/typography/fonts';
import media from '../../../../../../styles/mixins/media';
import {multiplyBase, rem} from '../../../../../../styles/mixins/calculate';
import ZIndexSettings from '../../../../../../styles/settings/z-index.settings';

export const Tracker = styled.div`
	// Grid position
	grid-area: tracker;
	font-family: ${fontType.numbers};
	display: flex;
	flex-direction: row;
	align-items: center;
	z-index: ${ZIndexSettings.tracker};
	
	${media.up('large')`
		justify-content: start;
	`}
`;

export const Divider = styled.span`
	width: ${multiplyBase(1)};
	height: ${rem(2)};
	background-color: ${colors.text.regular};
	margin-right: ${multiplyBase(2)};
	margin-left: ${multiplyBase(2)};
`;


export const CurrentIntegerWrapper = styled.div`
	position: relative;
	overflow: hidden;
	width: ${multiplyBase(2)};
	height: ${multiplyBase(2)};
`;

export const Integer = styled.div`
	position: absolute;
	right: 0;
	transform: translateY(16px);
`;

export const MaxInteger = styled.div`
	width: ${multiplyBase(2)};
	height: ${multiplyBase(2)};
`;
