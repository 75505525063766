import React, {useEffect, useState} from "react";
// Models
import {IMenuIconProps} from "./menu-icon.models";
// Styles
import * as S from "./menu-icon.styles";

export const MenuIcon = (props: IMenuIconProps) => {
	const {status, onClick, viewBoxHeight, rectHeight, delay} = props;

	const [isVisible, setVisibility] = useState(false);

	// The rect height
	const rHeight = rectHeight ? rectHeight : 4;
	// The viewBox height
	const vHeight = viewBoxHeight ? viewBoxHeight : 40;
	// The viewBox
	const viewBox = `0 0 48 ${vHeight}`;
	// Position Y of the rect
	const y = `${(vHeight / 2) - (rHeight / 2)}`;

	useEffect(() => {
		setTimeout(() => {
			setVisibility(true);
		}, delay ? delay * 1000 : 0);
	});

	if (!isVisible) return null;

	return (
		<S.MenuIcon onClick={onClick} status={status} viewBox={viewBox}>
			<rect x="4" y={y} width="40px" height={rHeight}/>
			<rect x="4" y={y} width="40px" height={rHeight}/>
			<rect x="4" y={y} width="40px" height={rHeight}/>
		</S.MenuIcon>
	);
};

export default MenuIcon;
