import {TimelineMax} from 'gsap';
import {animationSettings} from '../../../../../../../styles/settings/animation.settings';
import {pageCarouselOrientation} from '../../../artwork-carousel.models';
import ZIndexSettings from '../../../../../../../styles/settings/z-index.settings';


export const setArtworkContentIndex = (currentIndex: number, previousIndex?: number) => {
	const tl = new TimelineMax();
	const currentArtworkContentId = `#artwork-content-${currentIndex}`;

	tl.set(currentArtworkContentId, {
		css: {
			zIndex: ZIndexSettings.currentArtworkContent,
		}
	});

	if (previousIndex) {
		const previousArtworkContentId = `#artwork-content-${previousIndex}`;
		tl.set(previousArtworkContentId, {
			css: {
				zIndex: ZIndexSettings.previousArtworkContent,
			}
		});
	}
};


export const currentSocialMediaAnimation = (index: number, orientation: pageCarouselOrientation) => {
	const dribbbleId = `#dribbble-inner-${index}`;
	const behanceId = `#behance-inner-${index}`;
	const societySixId = `#society-six-inner-${index}`;
	const instagramId = `#instagram-inner-${index}`;
	const isNext = orientation === pageCarouselOrientation.NEXT;
	currentArtworkContentAnimation(dribbbleId, isNext, animationSettings.delay.shorter);
	currentArtworkContentAnimation(instagramId, isNext, animationSettings.delay.short);
	currentArtworkContentAnimation(societySixId, isNext, animationSettings.delay.medium);
	currentArtworkContentAnimation(behanceId, isNext, animationSettings.delay.long);
};

export const previousSocialMediaAnimation = (index: number, orientation: pageCarouselOrientation) => {
	const dribbbleId = `#dribbble-inner-${index}`;
	const behanceId = `#behance-inner-${index}`;
	const societySixId = `#society-six-${index}`;
	const instagramId = `#instagram-inner-${index}`;
	const isNext = orientation === pageCarouselOrientation.NEXT;
	previousArtworkContentAnimation(dribbbleId, isNext, animationSettings.delay.shorter);
	previousArtworkContentAnimation(instagramId, isNext, animationSettings.delay.short);
	previousArtworkContentAnimation(societySixId, isNext, animationSettings.delay.medium);
	previousArtworkContentAnimation(behanceId, isNext, animationSettings.delay.long);
};

export const previousTitleAnimation = (index: number, orientation: pageCarouselOrientation) => {
	const artworkTitleId = `#artwork-title-inner-${index}`;
	const isNext = orientation === pageCarouselOrientation.NEXT;
	previousArtworkContentAnimation(artworkTitleId, isNext);
};


export const currentTitleAnimation = (index: number, orientation: pageCarouselOrientation) => {
	const artworkTitleId = `#artwork-title-inner-${index}`;
	const isNext = orientation === pageCarouselOrientation.NEXT;
	currentArtworkContentAnimation(artworkTitleId, isNext);
};


export const currentArtworkContentAnimation = (id: string, isNext: boolean, delay?: number) => {
	const tl = new TimelineMax();

	tl.set(id, {
		css: {
			transform: isNext ? 'translateY(-50px)' : 'translateY(50px)'
		}
	});

	tl.to(id,
		animationSettings.speed.slow,
		{
			css: {
				transform: 'translateY(0)',
			}
		}
	).delay(delay ? delay : 0);
};

export const previousArtworkContentAnimation = (id: string, isNext: boolean, delay?: number) => {
	const tl = new TimelineMax();

	tl.set(id, {
		css: {
			transform: 'translateY(0)'
		}
	});

	tl.to(id,
		animationSettings.speed.slow,
		{
			css: {
				transform: isNext ? 'translateY(50px)' : 'translateY(-50px)',
			}
		}
	).delay(delay ? delay : 0);
};
