import React from 'react';
// Animation
import { animationSettings } from '../../styles/settings/animation.settings';
// Components
import { ArtworkCarousel } from './pages/ArtworkCarousel/ArtworkCarousel';
import { ArtworkOverview } from './pages/ArtworkOverview/ArtworkOverview';
import { Background } from '../../components/Background/Background';
import Logo from '../../components/Logo/Logo';
import { Magnetic } from '../../components/Magnetic/Magnetic';
import MenuIcon from '../../components/Menu/MenuIcon/MenuIcon';
import Navigation from '../../components/Menu/Navigation/Navigation';
import NavigationLink from '../../components/Menu/NavigationLink/NavigationLink';
import { NextIcon } from '../../components/NextPrev/components/NextIcon/NextIcon';
import { NotSupported } from './pages/NotSupported/NotSupported';
import { PrevIcon } from '../../components/NextPrev/components/PrevIcon/PrevIcon';
import { ReRenderer } from '../../components/ReRenderer/ReRenderer';
import { Tracker } from './pages/ArtworkCarousel/components/Tracker/Tracker';
// Content
import { ArtworkContent } from './portfolio.content';
// Models
import { BackgroundStatus } from '../../components/Background/background.model';
import { IArtworkModel, IBrowserModel, pageCarouselOrientation } from './pages/ArtworkCarousel/artwork-carousel.models';
import { LoadingStatus } from '../../models/loading.models';
import { MenuStatus } from '../../components/Menu/menu.models';
import { IPortfolioPagesModel, PortfolioPages } from './portfolio.models';
// Pages
import { AboutMe } from './pages/AboutMe/AboutMe';
// Styling
import colors from '../../styles/colors/colors';
import * as S from './portfolio.styles';
import { LoadingIcon } from '../../components/LoadingIcon/LoadingIcon';

interface iPortfolioProps {
}

interface iPortfolioState {
  status: LoadingStatus;
  // Show/hide the navigation
  isNavigationVisible: MenuStatus;
  // Time out for navigation to prevent weird animations
  navigationTimeOut: boolean;
  pageIsLoaded: boolean;
  showArtworkCarouselPage: boolean;
  showAboutMePage: boolean;
  showOverview: boolean;
  artwork: IArtworkModel;
  artworkWorkIndex: number;
  orientation: pageCarouselOrientation;
  debounce: boolean;
  notSupportedBrowser: IBrowserModel | null;
}

export class Portfolio extends React.Component<iPortfolioProps, iPortfolioState> {
  constructor(props: iPortfolioProps) {
    super(props);
    this.state = {
      status: LoadingStatus.LOADING,
      isNavigationVisible: MenuStatus.ONINIT,
      navigationTimeOut: false,
      showArtworkCarouselPage: true,
      showAboutMePage: false,
      showOverview: false,
      pageIsLoaded: false,
      artworkWorkIndex: 0,
      artwork: ArtworkContent[0],
      orientation: pageCarouselOrientation.INTRO,
      debounce: false,
      notSupportedBrowser: null,
    };
  }

  componentDidMount(): void {
    this.checkIfBrowserIsSupported();
    this.pageLoaded();
  }

  /**
   * Function to check if the browser is supported,
   * if so: do nothing, if not, block content
   */
  checkIfBrowserIsSupported() {
    const { detect } = require('detect-browser');
    const browser = detect();

    const browserName: string = browser.name;
    const browserVersion: string = browser.version;

    if (browserName === 'ie') {
      this.setState({
        notSupportedBrowser: { browserName, browserVersion },
      });
    }
  }

  /**
   * Function: pageLoaded
   * handle loading and loaded events
   */
  pageLoaded() {
    setTimeout(() => {
      this.setState({
        status: LoadingStatus.LOADED,
      });
      setTimeout(() => {
        this.setState({
          pageIsLoaded: true,
        });
      }, 500);
    }, 2000);
  }

  /**
   * Function: toggleNavigation
   * toggle the visibility of the navigation
   */
  toggleNavigation() {
    if (!this.state.navigationTimeOut) {
      this.setState({
        isNavigationVisible:
          this.state.isNavigationVisible === MenuStatus.ISOPEN || this.state.isNavigationVisible === MenuStatus.ONINIT
            ? MenuStatus.ISCLOSED
            : MenuStatus.ISOPEN,
        navigationTimeOut: true,
        orientation: pageCarouselOrientation.NONE,
      });

      setTimeout(() => {
        this.setState({
          navigationTimeOut: false,
        });
      }, 800);
    }
  }

  /**
   * Function: navigate
   * handle navigating between pages
   * @param page
   * @param closeNavigation
   */
  navigate(page: PortfolioPages, closeNavigation: boolean = false) {
    let pageSetting: IPortfolioPagesModel | null = null;

    // About me page settings
    if (page === PortfolioPages.ABOUTME) {
      pageSetting = {
        showArtworkCarouselPage: false,
        showAboutMePage: true,
        showOverview: false,
      };
    }

    // Overview page settings
    if (page === PortfolioPages.OVERVIEW) {
      pageSetting = {
        showArtworkCarouselPage: false,
        showAboutMePage: false,
        showOverview: true,
      };
    }

    // Overview page settings
    if (page === PortfolioPages.CAROUSEL) {
      pageSetting = {
        showArtworkCarouselPage: true,
        showAboutMePage: false,
        showOverview: false,
      };
    }

    if (pageSetting) {
      this.setState({
        showArtworkCarouselPage: pageSetting.showArtworkCarouselPage,
        showAboutMePage: pageSetting.showAboutMePage,
        showOverview: pageSetting.showOverview,
      });
    }

    // Close navigation
    if (closeNavigation) {
      this.toggleNavigation();
    }
  }

  /**
   * Function: showPrevArtwork
   * Handle logic to show previous artwork
   */
  showPrevArtwork() {
    let newIndex = this.state.artworkWorkIndex - 1 === -1 ? ArtworkContent.length - 1 : this.state.artworkWorkIndex - 1;
    if (!this.state.debounce) {
      this.WaitForAnimation(750);
      this.setState({
        artwork: ArtworkContent[newIndex],
        artworkWorkIndex: newIndex,
        orientation: pageCarouselOrientation.PREV,
      });
    }
  }

  /**
   * Function: showNextArtwork
   * Handle logic to next previous artwork
   */
  showNextArtwork() {
    let newIndex = ArtworkContent.length === this.state.artworkWorkIndex + 1 ? 0 : this.state.artworkWorkIndex + 1;
    if (!this.state.debounce) {
      this.WaitForAnimation(750);
      this.setState({
        artwork: ArtworkContent[newIndex],
        artworkWorkIndex: newIndex,
        orientation: pageCarouselOrientation.NEXT,
      });
    }
  }

  /**
   * Function: WaitForAnimation
   * Set a debounce when a action needs to wait for a animation
   * @param debounceTime
   * @constructor
   */
  WaitForAnimation(debounceTime: number) {
    this.setState({
      debounce: true,
    });
    setTimeout(() => {
      this.setState({
        debounce: false,
      });
    }, debounceTime);
  }

  /**
   * Function: selectArtwork
   * Select an artwork to set as current artwork for carousel
   * @param artwork
   * @param index
   */
  selectArtwork(artwork: IArtworkModel, index: number) {
    this.setState({
      artwork: artwork,
      showOverview: false,
      artworkWorkIndex: index,
    });
  };

  render() {
    const {
      artwork,
      artworkWorkIndex,
      notSupportedBrowser,
      orientation,
      showAboutMePage,
      showArtworkCarouselPage,
      showOverview,
      status,
      pageIsLoaded,
    } = this.state;
    const defaultDelay = .5;

    return (
      !notSupportedBrowser ? (

        <S.PortfolioGrid>
          {pageIsLoaded && (
            <>
              <Logo delay={defaultDelay + animationSettings.portfolio.introDelay.logo}/>
              <MenuIcon onClick={() => this.toggleNavigation()}
                        status={this.state.isNavigationVisible}
                        delay={defaultDelay + animationSettings.portfolio.introDelay.menuIcon}/>

              <Navigation status={this.state.isNavigationVisible}>
                <NavigationLink
                  onClick={() => this.navigate(PortfolioPages.CAROUSEL, true)}>Illustrations</NavigationLink>
                <NavigationLink onClick={() => this.navigate(PortfolioPages.ABOUTME, true)}>About me</NavigationLink>
              </Navigation>

              {showArtworkCarouselPage && !showOverview && (
                <ReRenderer>
                  <Tracker currentInteger={artworkWorkIndex + 1}
                           maxInteger={ArtworkContent.length}
                           delay={defaultDelay + animationSettings.portfolio.introDelay.tracker}
                           orientation={orientation}/>

                  <Magnetic className="prev"
                            delay={defaultDelay + animationSettings.portfolio.introDelay.nextPrev}
                            onClick={() => this.showPrevArtwork()}>
                    <PrevIcon/>
                  </Magnetic>

                  <Magnetic className="next"
                            delay={defaultDelay + animationSettings.portfolio.introDelay.nextPrev}
                            onClick={() => this.showNextArtwork()}>
                    <NextIcon/>
                  </Magnetic>

                  <ArtworkCarousel artworks={ArtworkContent}
                                   currentArtworkIndex={artworkWorkIndex}
                                   orientation={orientation}
                                   delayArtwork={defaultDelay + animationSettings.portfolio.introDelay.artwork}
                                   delayBackgroundCircle={defaultDelay + animationSettings.portfolio.introDelay.circleBackground}
                                   {...artwork} />
                </ReRenderer>
              )}
              {showAboutMePage && (
                <AboutMe/>
              )}

              {showOverview && (
                <ArtworkOverview artworks={ArtworkContent}
                                 clickArtwork={this.selectArtwork.bind(this)}/>
              )}
            </>
          )}



          <LoadingIcon status={status}/>

          <Background
            from={colors.background.gradient.light.from}
            to={colors.background.gradient.light.to}
            status={showArtworkCarouselPage ? BackgroundStatus.ENTER : BackgroundStatus.LEAVE}
          />
          <Background
            from={colors.background.gradient.light.to}
            to={colors.background.gradient.light.from}
            status={showAboutMePage ? BackgroundStatus.ENTER : BackgroundStatus.LEAVE}
          />
        </S.PortfolioGrid>

      ) : (
        <NotSupported browser={notSupportedBrowser}/>
      )
    );
  }
}

export default Portfolio;
