const ZIndexValues = {
  base: 0,
  bottomBottom: 1,
  bottomTop: 2,
  middleBottom: 3,
  middle: 4,
  top: 5
};

export const ZIndexSettings = {
  navigation: ZIndexValues.middle,
  menuButton: ZIndexValues.top,
  loadingIcon: ZIndexValues.top,
  background: ZIndexValues.base,
  logo: ZIndexValues.bottomTop,
  tracker: ZIndexValues.bottomTop,
  artworkOverview: ZIndexValues.bottomBottom,
  artwork: ZIndexValues.bottomBottom,
  aboutMe: ZIndexValues.bottomBottom,
  nextPrev: ZIndexValues.bottomTop,
  previousArtworkContent: ZIndexValues.bottomTop,
  currentArtworkContent: ZIndexValues.middleBottom,
  overviewButton: ZIndexValues.bottomTop
};

export default ZIndexSettings;
