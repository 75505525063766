import React, {Component} from 'react';
// Models
import {ICircleBackgroundProps} from '../../artwork-carousel.models';
// Styles
import * as S from './circle-background.styles';
import {
	animateMoveBackgroundImage,
	gradientAnimation,
	innerCircleInitAnimation,
} from './animations/circle-background.animation';

interface state {
	gradient: {
		from: string;
		to: string;
	};
}

export class CircleBackground extends Component<ICircleBackgroundProps, state> {
	private innerCircleGradient = 'innerCircleGradient';
	private innerCircleImage = 'innerCircleImage';
	private backgroundImage = 'backgroundImage';

	constructor(props: ICircleBackgroundProps) {
		super(props);
		// Set the gradient based on the initial prop in the state
		// The state is used to set the initial gradient
		this.state = {
			gradient: {
				from: this.props.gradientFrom,
				to: this.props.gradientTo,
			},
		};
	}

	componentDidMount() {
		innerCircleInitAnimation(this.innerCircleImage);
		innerCircleInitAnimation(this.innerCircleGradient);
		animateMoveBackgroundImage(this.backgroundImage);
	}

	componentDidUpdate(previousProps: Readonly<ICircleBackgroundProps>) {
		const {gradientTo, gradientFrom} = this.props;
		if (previousProps.gradientTo !== gradientTo || previousProps.gradientFrom !== gradientFrom) {
			gradientAnimation(gradientFrom, gradientTo);
		}
	}

	render() {
		const {backgroundImage} = this.props;
		const {gradient} = this.state;
		// Center of the circle
		const cCenter = 240;
		// Dimension of the circle
		const cDimension = cCenter * 2;
		// Radius of the circle
		const cRadius = (cCenter / 2) + 60;
		// ViewBox of the circle
		const viewBox = `0 0 ${cDimension} ${cDimension}`;
		// BackgroundImage extension
		const backgroundImageExtension = '.jpg';
		// Ids
		const id = {mask: 'mask', shadow: 'shadow', gradient: 'gradient'};

		return (
			<React.Fragment>
				<S.CircleBackground viewBox={viewBox}>
					<defs>
						<filter id={id.shadow} x="-40%" y="-40%" width="200%" height="200%">
							<S.FeDropShadow dx="0" dy="10" stdDeviation="26" floodOpacity="1"/>
						</filter>
						<linearGradient id={id.gradient} x1="0%" y1="0%" x2="0" y2="100%">
							<stop id="starter" offset="0" style={{stopColor: gradient.from}}/>
							<stop id="stopper" offset="1" style={{stopColor: gradient.to}}/>
						</linearGradient>
					</defs>
					<mask id={id.mask}>
						<S.InnerCircle
							id={this.innerCircleImage}
							radius={cCenter}
							cx={cCenter}
							cy={cCenter}
							r={cRadius}
							fill="white"
						/>
					</mask>
					<S.InnerCircle
						id={this.innerCircleGradient}
						radius={cCenter}
						filter={`url(#${id.shadow})`}
						fill={`url(#${id.gradient})`}
						cx={cCenter}
						cy={cCenter}
						r={cRadius}
					/>
					<S.Img
						id={this.backgroundImage}
						width="900"
						height="600"
						mask={`url(#${id.mask})`}
						xlinkHref={require(`../../../../../../assets/images/backgrounds/${backgroundImage}${backgroundImageExtension}`)}
					/>
					<S.OuterCircle cx={cCenter} cy={cCenter} r={cRadius + 16}/>
				</S.CircleBackground>
			</React.Fragment>
		);
	}
}
