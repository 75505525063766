import styled from 'styled-components';
// Settings
import colors from '../../../../../../styles/colors/colors';
import FontSize from '../../../../../../styles/typography/font-size';
import media from '../../../../../../styles/mixins/media';
import {multiplyBase} from '../../../../../../styles/mixins/calculate';
import ZIndexSettings from '../../../../../../styles/settings/z-index.settings';
import fontType from '../../../../../../styles/typography/fonts';

export const ArtworkContent = styled.div`
	// Grid position
	grid-area: artwork-content;
	
	${media.up('large')`
		align-self: end;
		width: 150%;
	`};
	
	z-index: ${ZIndexSettings.previousArtworkContent};
`;

export const ArtworkTitleInner = styled.span`
	position: absolute;
	transform: translateY(50px);
	text-align: center;
	width: 100%;
	
	${media.up('large')`
		text-align: start;
	`}
`;

export const SocialMediaInner = styled.div`
	position: absolute;
	transform: translateY(50px);
	
	${media.up('large')`
		height: ${multiplyBase(4)};
	`}
	
	&:nth-child(1) {
		${media.down('small')`
			margin-left: -${multiplyBase(3)};
		`};
	}
	&:nth-child(2) {
		${media.down('small')`
			margin-left: ${multiplyBase(3)};
		`};
		${media.up('large')`
			left: ${multiplyBase(5)};
		`};
	}
`;

export const ArtworkTitle = styled.h1`
	// Text styling
	font-family: ${fontType.title};
	color: ${colors.text.regular};
	font-size: ${FontSize.mobile.larger};
	height: ${multiplyBase(3)};
	
	position: relative;
	overflow: hidden;
	
	grid-area: title;
	align-self: end;
	margin-bottom: ${multiplyBase(1)};
	
	${media.up('large')`
		height: ${multiplyBase(6)};
		font-size: ${FontSize.desktop.huge};
	`}
`;

export const SocialMedia = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	height: ${multiplyBase(4)};
	position: relative;
	overflow: hidden;
	
	> *:not(:last-child) {
		margin-right: ${multiplyBase(1)};
	}
	
	${media.up('large')`
		justify-content: start;
	`};
`;

export const SocialMediaLink = styled.a`
	display: block;
	width: ${multiplyBase(4)};
	height: ${multiplyBase(4)};
`;
