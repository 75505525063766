import { keyframes } from 'styled-components';

export const lineAnimation = (dashArray: number, dashOffset: number) => {
  return keyframes`
    0% {
      stroke-dasharray: ${dashArray}px;
      stroke-dashoffset: ${dashOffset}px;
    }
    100% {
      stroke-dasharray: ${dashArray}px;
      stroke-dashoffset: 0;
    }
`;
};
