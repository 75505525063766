import { keyframes } from "styled-components";

export const menuAnimationTop = keyframes`
    0% {
      transform: rotate(45deg);
    }
    50% {
      transform: translateY(0) rotate(0);
    }
    100% {
      transform: translateY(-16px);
    }
`;

export const menuAnimationMiddle = keyframes`
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
`;

export const menuAnimationBottom = keyframes`
    0% {
      transform-origin: 50% 50%;
      transform: rotate(-45deg) scaleX(1);
    }
    50% {
      transform-origin: 50% 50%;
      transform: rotate(0) translateY(0);
    }
    100% {
      transform-origin: 4px 50%;
      transform: translateY(16px) scaleX(.5);
    }
`;
