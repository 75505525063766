import React from 'react';

import * as S from './instagram-icon.styles';

export const InstagramIcon = () => {

	return (
		<S.InstagramIcon viewBox="0 0 26 26">
			<path d="M25 5.003c0-1.061-.421-2.078-1.172-2.829-.75-.75-1.767-1.171-2.828-1.171H5c-1.061 0-2.078.421-2.828 1.171C1.421 2.925 1 3.942 1 5.003V21c0 1.061.421 2.078 1.172 2.828C2.922 24.579 3.939 25 5 25h16c1.061 0 2.078-.421 2.828-1.172C24.579 23.078 25 22.061 25 21V5.003zM23 6c0-1.657-1.343-3-3-3H6C4.343 3 3 4.343 3 6v14c0 1.657 1.343 3 3 3h14c1.657 0 3-1.343 3-3V6z"/>
			<path d="M13 8c2.76 0 5 2.24 5 5s-2.24 5-5 5-5-2.24-5-5 2.24-5 5-5zm0 2c1.656 0 3 1.344 3 3s-1.344 3-3 3-3-1.344-3-3 1.344-3 3-3z"/>
			<circle cx="18.652" cy="6.652" r="1.652" transform="translate(.695331)"/>
		</S.InstagramIcon>
	);
}
