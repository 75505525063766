import styled from 'styled-components';
import colors from '../../../../../../../../styles/colors/colors';

export const InstagramIcon = styled.svg`
	display: block;
	fill-rule: evenodd;
	fill: ${colors.icon.regular.default};
	
	&:hover {
		fill: ${colors.icon.regular.hover};
	}
`;
