import { TimelineMax, TweenMax } from 'gsap';
import { animationSettings } from '../../../../../../../styles/settings/animation.settings';

export const artworkInitAnimation = (currentArtwork: string) => {
  const currentArtworkMaskId = `#${currentArtwork}-mask`;
  new TimelineMax().to(
    currentArtworkMaskId,
    animationSettings.speed.slowest * 2.5,
    {
      attr: {
        points: '0 0, 2200 0, 2200 2400, 0 2400',
      },
    },
    0
  );
};

export const artworkPrevAnimation = (
    currentArtwork: string,
    previousArtwork: string
) => {
  const tl = new TimelineMax();
  const currentArtworkMaskId = `#${currentArtwork}-mask`;
  const previousArtworkMaskId = `#${previousArtwork}-mask`;

  TweenMax.set(previousArtworkMaskId, {
    attr: {
      points: '0 0, 2200 0, 2200 2400, 0 2400',
    },
  });

  TweenMax.set(currentArtworkMaskId, {
    attr: {
      points: '2200 0, 2200 0, 2200 2400, 2200 2400',
    },
  });

  tl.to(
      currentArtworkMaskId,
      animationSettings.speed.slowest,
      {
        attr: {
          points: '0 0, 2200 0, 2200 2400, 0 2400',
        },
      },
      0
  );

  tl.to(
      previousArtworkMaskId,
      animationSettings.speed.slowest,
      {
        attr: {
          points: '0 0, 0 0, 0 2400, 0 2400',
        },
      },
      0
  );
};

export const artworkNextAnimation = (
  currentArtwork: string,
  previousArtwork: string
) => {
  const tl = new TimelineMax();
  const currentArtworkMaskId = `#${currentArtwork}-mask`;
  const previousArtworkMaskId = `#${previousArtwork}-mask`;

  TweenMax.set(previousArtworkMaskId, {
    attr: {
      points: '0 0, 2200 0, 2200 2400, 0 2400',
    },
  });

  TweenMax.set(currentArtworkMaskId, {
    attr: {
      points: '0 0, 0 0, 0 2400, 0 2400',
    },
  });

  tl.to(
    currentArtworkMaskId,
    animationSettings.speed.slowest*2,
    {
      attr: {
        points: '0 0, 2200 0, 2200 2400, 0 2400',
      },
    },
    0
  );

  tl.to(
    previousArtworkMaskId,
    animationSettings.speed.slowest*2,
    {
      attr: {
        points: '2200 0, 2200 0, 2200 2400, 2200 2400',
      },
    },
    0
  );
};
