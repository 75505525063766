import styled, {css} from "styled-components";
// Settings
import {animationSettings} from '../../../styles/settings/animation.settings';
import colors from "../../../styles/colors/colors";
import FontSize from "../../../styles/typography/font-size";
import { hoverFocus } from "../../../styles/mixins/hover-focus";
import media from "../../../styles/mixins/media";
import { multiplyBase } from "../../../styles/mixins/calculate";

export const NavigationSpan = styled.span`
	display: block;
	height: ${multiplyBase(.5)};
	width: 100%;
	background-color: ${colors.link.light.default};
	transform: scaleX(0);
	transition: transform ${animationSettings.speed.fastest}s linear;
`;

export const NavigationLink = styled.a`
  text-decoration: none;
  font-size: ${FontSize.desktop.larger};
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  color: ${colors.link.light.default};
  transition: transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  padding: ${multiplyBase(3)};
  cursor: pointer;

  ${hoverFocus(css`
    &:hover {
     ${NavigationSpan} {
     transform: scaleX(1);
     }
    }
  `)};

  ${media.up('medium')`
    font-size: ${FontSize.desktop.huge};
  `};
`;

