import styled, {css} from "styled-components";
// Animations
import {navigationCloseAnimation} from "./animations/navigation-close.animation";
import {navigationOpenAnimation} from "./animations/navigation-open.animation";
// Models
import {MenuStatus} from "../menu.models";
// Settings
import colors from "../../../styles/colors/colors";
import media from "../../../styles/mixins/media";
// (Styled) components
import {NavigationLink} from "../NavigationLink/navigation-link.styles";

interface NavigationStylesProps {
	status: MenuStatus;
	amountOfItems: number;
}

export const Navigation = styled.div<NavigationStylesProps>`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: ${colors.background.red};

	${props => media.up('medium')`
		display: grid;
		grid-template-columns: repeat(${props.amountOfItems}, 1fr);
		align-items: center;
		justify-items: center;
	`};

	${props => props.status === MenuStatus.ONINIT && css`
		clip-path: polygon(0 50%, 100% 50%, 100% 50%, 0 50%);
	`};

	${props => props.status === MenuStatus.ISCLOSED && css`
		animation: ${navigationCloseAnimation} 0.4s cubic-bezier(0.23, 1, 0.32, 1);
		clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
		
		${NavigationLink} {
			transform: scale(1);
		}
	`};

	${props => props.status === MenuStatus.ISOPEN && css`
		animation: ${navigationOpenAnimation} 0.4s cubic-bezier(0.23, 1, 0.32, 1);
		clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
		
		${NavigationLink} {
			transform: scale(0.5);
		}
	`};
`;
