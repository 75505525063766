import styled from 'styled-components';
import colors from '../../../../../../../../styles/colors/colors';

export const SocietySixIcon = styled.svg`
	fill: ${colors.icon.regular.default};
	fill-rule: evenodd;
	
	&:hover {
		fill: ${colors.icon.regular.hover};
	}
`;
