import styled, { css, keyframes } from 'styled-components';
// Models
import { ILoadingIconProps } from './loading-icon.models';
// Settings
import { animationSettings } from '../../styles/settings/animation.settings';
import { LoadingStatus } from '../../models/loading.models';

export const LoadingIconRectAnimation = keyframes`
  0% {
    transform: scaleY(0);
  }
  50% {
    transform: scaleY(1);
  }
  100% {
    transform: scaleY(0);
  }
`;


export const LoadingIconRectLoadedAnimation = keyframes`
  0% {
    transform: scaleY(0);
  }
  25% {
    transform: scaleY(1);
  }
  100% {
    transform: scaleY(0);
  }
`;

// Loading Icon
// -------------------------------------------------------------------------------
export const LoadingIcon = styled.div`
  position: relative;
  
  svg {
    position: absolute;
  }
`;

// Loading
// -------------------------------------------------------------------------------
export const Loading = styled.svg<ILoadingIconProps>`
  rect {  
    transform-origin: center center;
    transform: scaleY(0);
    
    &:nth-of-type(1) {
      animation: ${LoadingIconRectAnimation} ${animationSettings.speed.slowest}s ease-in-out infinite;
      animation-delay: ${animationSettings.delay.medium}s;
    }
    
    &:nth-of-type(2) {
      animation: ${LoadingIconRectAnimation} ${animationSettings.speed.slowest}s ease-in-out infinite;
    }
    
    &:nth-of-type(3) {
      animation: ${LoadingIconRectAnimation} ${animationSettings.speed.slowest}s ease-in-out infinite;
      animation-delay: ${animationSettings.delay.medium * 2}s;
    }
  }
`;

// Loaded
// -------------------------------------------------------------------------------
export const Loaded = styled.svg<ILoadingIconProps>`
  rect {
    transform-origin: center center;
    transform: scaleY(0);
    ${props => props.status === LoadingStatus.LOADED && css`
        animation: ${LoadingIconRectLoadedAnimation} ${animationSettings.speed.slowest}s ease-in-out forwards;
    `}
  }
`;
