import styled, { css } from 'styled-components';
// Animations
import { BarInitAnimation } from '../../animations/next-prev.animations';
// Settings
import { animationSettings } from '../../../../styles/settings/animation.settings';
import colors from '../../../../styles/colors/colors';
import {hoverFocus} from '../../../../styles/mixins/hover-focus';
import {multiplyBase} from '../../../../styles/mixins/calculate';

export const NextIcon = styled.svg`
	display: block;
	stroke: ${colors.icon.regular.default};
	transition: transform 0.2s cubic-bezier(0.23, 1, 0.32, 1), stroke 0.1s cubic-bezier(0.23, 1, 0.32, 1);
	
	width: ${multiplyBase(6)};
	height: ${multiplyBase(6)};
	
	.bar {
		stroke-dasharray: 36px;
		stroke-dashoffset: 36px;
		animation: ${BarInitAnimation} ${animationSettings.speed.slow}s forwards;
	}
	
	.arrow-bar--left {
		stroke-dasharray: 24px;
		stroke-dashoffset: 24px;
		animation: ${BarInitAnimation} ${animationSettings.speed.slow}s forwards;
		animation-delay: ${animationSettings.delay.short}s;
	}
	
	.arrow-bar--right {
		stroke-dasharray: 24px;
		stroke-dashoffset: 24px;
		animation: ${BarInitAnimation} ${animationSettings.speed.slow}s forwards;
		animation-delay: ${animationSettings.delay.short}s;
	}

	${hoverFocus(css`
		&:hover {
			transform: scale(0.95);
			stroke: ${colors.icon.regular.hover};
			circle {
				stroke-dasharray: 200;
				stroke-dashoffset: 0;
			}
		}
	`)};

	circle {
		stroke-dashoffset: 200;
		stroke-dasharray: 200;
		transition: all 0.4s ease-out; //@TODO clean up all
	}
	
	g {
		transform: translate(8px, 4px);
	}
`;
