import styled from 'styled-components';
// Components
import {CircleBackground} from './components/CircleBackground/circle-background.styles';
import {Artwork} from './components/Artwork/artwork.styles';
// Settings
import media from '../../../../styles/mixins/media';
import {multiplyBase} from '../../../../styles/mixins/calculate';

export const ArtworkCarousel = styled.div`
	// Grid position
	grid-area: artwork-carousel;
	
	position: relative;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr;
	max-height: 100%;
	box-sizing: border-box;
	justify-items: center;

	${Artwork} {
		position: absolute;
		display: block;
		z-index: 1;
		height: 100%;
		width: 100%;
		align-self: end;
		
		g.centered {
			align-self: center;
			height: auto;
			width: ${multiplyBase(37)};
		}
		
		${media.up('smaller')`
			g.centered {
				width: ${multiplyBase(47)};
			}
		`};
		
		${media.up('medium')`
			g.centered {
				width: ${multiplyBase(82)}
			}
		`}
		
		${media.up('large')`
			g.centered {
				width: ${multiplyBase(72)};
				transform: translateY(-80px);
			}
		`}
		
		${media.up('largest')`
			g.centered {
				width: ${multiplyBase(82)}
			}
		`}
	}

	${CircleBackground} {
		height: auto;
		z-index: 0;
		grid-row: 1;
		grid-column: 1;
		align-self: center;
		width: ${multiplyBase(35)};
				
		${media.up('smaller')`
			width: ${multiplyBase(45)};
		`}
		
		${media.up('medium')`
			width: ${multiplyBase(80)};
		`};
		
		${media.up('large')`
			width: ${multiplyBase(70)};
			transform: translateY(-80px);
		`}
		
		${media.up('largest')`
			align-self: center;
			width: ${multiplyBase(80)};
		`}
	}
`;
