import React from 'react';
import * as S from './not-supported.styles';
import {INotSupportedProps} from './not-supported.models';

export const NotSupported = (props: INotSupportedProps) => {
	const {browserName, browserVersion} = props.browser;
	return (
		<S.NotSupported>
			<h1>Sorry, I do not support this browser</h1>
			<p>{browserName} {browserVersion}</p>
		</S.NotSupported>
	);
}
