import React from "react";
import * as S from "./navigation-link.styles";

export const NavigationLink = (props: { children: React.ReactNode, onClick: () =>  void}) => {
    return (
        <S.NavigationLink onClick={props.onClick}>
            {props.children}
            <S.NavigationSpan />
        </S.NavigationLink>
    );
};

export default NavigationLink;
