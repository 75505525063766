import { keyframes } from "styled-components";

export const overviewIconInitAnimation = keyframes`
    0% {
      height: 0;
      width: 2px;
    }
    40% {
      height: 16px;
      width: 2px;
    }
    60% {
      height: 16px;
      width: 2px;
    }
    100% {
      height: 16px;
      width: 32px;
    }
`;
