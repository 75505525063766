import {css, CSSObject, SimpleInterpolation} from 'styled-components';
import {GridBreakpoints} from "./mixin.models";

export type MediaQueryVariants = 'up' | 'down' | 'only' | 'between';
type ValidBreakpoint = keyof GridBreakpoints;
const BREAKPOINTS = {
    // XS size is set to 0, because other wise if you'd use a breakpoint
    // between XS and SM, everything under 320px would not be styled.
    base: '0px',
    smallest: '320px',
    smaller: '375px',
    small: '480px',
    medium: '768px',
    large: '1024px',
    larger: '1200px',
    largest: '1440px',
};

/* Media query helpers */

/**
 * Checks whether media query exists in BREAKPOINTS object.
 * @param breakpoint Breakpoint name
 * @param throwOnError By default this function throws an error if it is invalid.
 * You can bypass that with this boolean
 */
const isValidBreakpoint = (breakpoint: ValidBreakpoint, throwOnError: boolean = true) => {
    if (!BREAKPOINTS[breakpoint] && throwOnError) throw new Error(`Using non existing breakpoint: ${breakpoint}`);
    return !!BREAKPOINTS[breakpoint];
};

/**
 * Checks if there is a breakpoing that comes after the 'breakpoint' argument.
 * @param breakpoint Breakpoint name
 * @param throwOnError By default this function throws an error if it is invalid.
 * You can bypass that with this boolean
 */
export const getNextBreakpoint = (breakpoint: ValidBreakpoint, throwOnError: boolean = true) => {
    isValidBreakpoint(breakpoint);

    const keys = Object.keys(BREAKPOINTS);
    const index = keys.indexOf(breakpoint);
    const nextBreakpoint = keys[index + 1];

    if (!nextBreakpoint && throwOnError) {
        throw new Error(`${breakpoint} is the biggest breakpoint there is. So '${breakpoint} and down' doesn't exist.`);
    }

    return nextBreakpoint as ValidBreakpoint;
};

/* Media query methods */

/**
 * From this breakpoint (including) and up.
 * @param breakpoint
 */
const up = (breakpoint: ValidBreakpoint) => (
    first: CSSObject | TemplateStringsArray,
    ...interpolations: SimpleInterpolation[]
) => {
    isValidBreakpoint(breakpoint);

    return css`
    @media only screen and (min-width: ${BREAKPOINTS[breakpoint]}) {
      ${css(first, ...interpolations)}
    }
  `;
};

/**
 * Until this breakpoint (icluding).
 * @param breakpoint
 */
const down = (breakpoint: ValidBreakpoint) => (
    first: CSSObject | TemplateStringsArray,
    ...interpolations: SimpleInterpolation[]
) => {
    isValidBreakpoint(breakpoint);
    const nextBreakpoint = getNextBreakpoint(breakpoint);

    return css`
    @media only screen and (max-width: ${`${parseInt(BREAKPOINTS[nextBreakpoint], 10) - 1}px`}) {
      ${css(first, ...interpolations)}
    }
  `;
};

/**
 * From breakpoint 'min' until breakpoint 'max'.
 * @param min
 * @param max
 */
const between = (min: ValidBreakpoint, max: ValidBreakpoint) => (
    first: CSSObject | TemplateStringsArray,
    ...interpolations: SimpleInterpolation[]
) => {
    isValidBreakpoint(min);
    isValidBreakpoint(max);

    return css`
    @media only screen and (min-width: ${BREAKPOINTS[min]}) and (max-width: ${`${parseInt(BREAKPOINTS[max], 10) -
    1}px`}) {
      ${css(first, ...interpolations)}
    }
  `;
};

/**
 * Only a specific range. Eg only('md') will result in between('md', 'lg').
 * @param breakpoint
 */
const only = (breakpoint: ValidBreakpoint) => {
    const nextBreakpoint = getNextBreakpoint(breakpoint, false);
    return isValidBreakpoint(nextBreakpoint, false) ? between(breakpoint, nextBreakpoint) : up(breakpoint);
};

export default {
    up,
    down,
    between,
    only,
};
