import React from 'react';
// Styles
import * as S from './dribbble-icon.styles';

export const DribbbleIcon = () => {
	return (
		<S.DribbbleIcon width="100%" height="100%" viewBox="0 0 26 26">
			<g id="circle" transform="matrix(1.04762,0,0,1.04762,-0.619048,-1.92474)">
				<circle cx="13" cy="14.246" r="10.5"/>
			</g>
			<path id="curve-horizontal-top" d="M1.641,11.194C1.641,11.194 12.267,13.562 21.5,5.257"/>
			<path id="curve-horizontal-bottom" d="M5.833,21.318C7.789,17.87 13.336,10.654 24,14.645"/>
			<path id="curve-vertical" d="M9.161,2.718C10.825,4.668 15.25,10.765 18,23.057"/>
		</S.DribbbleIcon>
	);
};
