import styled from 'styled-components';
import colors from '../../../../../../styles/colors/colors';
import {outerCircleInitAnimation} from './animations/circle-background.animation';
import {animationSettings} from '../../../../../../styles/settings/animation.settings';

interface InnerCircleStyles {
	radius?: number;
}

export const CircleBackground = styled.svg`
	display: block;
	overflow: auto;
`;

export const Img = styled.image`
	opacity: 0.5;
	z-index: 1;
	height: 90%;
	width: auto;
`;

export const InnerCircle = styled.circle<InnerCircleStyles>`
	display: block;
`;

export const OuterCircle = styled.circle`
	stroke-dashoffset: 1200;
	stroke-dasharray: 1200;
	animation: ${outerCircleInitAnimation} 2s ease-in-out forwards;
	transition: all ${animationSettings.speed.slow}s ease-out;
	
	fill: none;
	stroke: ${colors.background.light};
	stroke-width: 2;
`;

export const FeDropShadow = styled('feDropShadow')`
	flood-color: ${colors.shadow};
`;
