import styled from 'styled-components';
// Settings
import media from '../../styles/mixins/media';
import { multiplyBase } from '../../styles/mixins/calculate';
import ZIndexSettings from '../../styles/settings/z-index.settings';
// Styled components
import { AboutMe } from './pages/AboutMe/about-me.styles';
import { ArtworkCarousel } from './pages/ArtworkCarousel/artwork-carousel.styles';
import { ArtworkOverview } from './pages/ArtworkOverview/artwork-overview.styles';
import { Background } from '../../components/Background/background.styles';
import { Magnetic } from '../../components/Magnetic/magnetic.styles';
import { Navigation } from '../../components/Menu/Navigation/navigation.styles';
import { MenuIcon } from '../../components/Menu/MenuIcon/menu-icon.styles';
import { OverviewIcon } from '../../components/OverviewIcon/overview-icon.styles';
import { Tracker } from './pages/ArtworkCarousel/components/Tracker/tracker.styles';
import { Logo } from '../../components/Logo/logo.styles';
import { animationSettings } from '../../styles/settings/animation.settings';
import { LoadingIcon } from '../../components/LoadingIcon/loading-icon.styles';

export const PortfolioGrid = styled.div`
	width: 100vw;
	height: 100vh;
	max-height: 100vh;
	box-sizing: border-box;
	overflow: hidden;
	
	// Grid settings
	display: grid;
	grid-template-columns:
		[full-start]
		${multiplyBase(1)}
		1fr 1fr 1fr 1fr
		${multiplyBase(1)}
		[full-end];
	grid-template-rows:
		${multiplyBase(1)}
		${multiplyBase(14)}
		${multiplyBase(6)}
		${multiplyBase(8)}
		${multiplyBase(8)}
		auto
		${multiplyBase(1)};
	grid-template-areas: 
		'. . . . . .'
		'. logo logo menu-button menu-button  .'
		'. tracker tracker tracker tracker .'
		'. artwork-content artwork-content artwork-content artwork-content .'
		'. prev prev next next .'
		'. artwork-carousel artwork-carousel artwork-carousel artwork-carousel .'
		'. artwork-carousel artwork-carousel artwork-carousel artwork-carousel .'
	;
	
	${media.up('medium')`
		grid-template-columns: 
			[full-start] 
			${multiplyBase(4)}
			1fr 
			4fr 
			1fr 
			${multiplyBase(4)}
			[full-end];
		grid-gap: ${multiplyBase(2)};
		grid-template-rows:
			${multiplyBase(4)}
			${multiplyBase(10)}
			1fr
			1fr
			${multiplyBase(10)}
			${multiplyBase(4)};
		grid-template-areas: 
			'. . . . .' 
			'. logo . menu-button .'
			'. tracker artwork-carousel prev .'
			'. artwork-content artwork-carousel next  .'
			'. overview-button artwork-carousel .  .'
			'. . artwork-carousel .  .'
	`};

	${media.up('large')` 
		grid-template-columns: 
			[full-start] 
			${multiplyBase(4)} 
			2fr 
			4fr 
			2fr 
			${multiplyBase(4)} 
			[full-end];
		grid-gap: ${multiplyBase(2)};
		grid-template-rows:
			${multiplyBase(4)}
			${multiplyBase(10)}
			1fr
			1fr
			${multiplyBase(10)}
			${multiplyBase(4)};
		grid-template-areas: 
			'. . . . .' 
			'. logo . menu-button .'
			'. tracker artwork-carousel prev .'
			'. artwork-content artwork-carousel next  .'
			'. overview-button artwork-carousel .  .'
			'. . artwork-carousel .  .'
	`};
	
	// Logo
	// -------------------------------------------------------------------------------
	${Logo} {
		// Grid position  
		grid-area: logo;
		
		${media.down('small')`
			width: ${multiplyBase(12)};
			height: ${multiplyBase(12)};
		`};
		

		width: ${multiplyBase(16)};
		height: ${multiplyBase(16)};
		z-index: ${ZIndexSettings.logo};
	}
	
	// Loading Icon
	// -------------------------------------------------------------------------------
	${LoadingIcon} {
		width: ${multiplyBase(4)};
		height: ${multiplyBase(4)};
		z-index: ${ZIndexSettings.loadingIcon};
		
		// Center element
		position: absolute;
		margin-left: auto;
		margin-right: auto;
		left: 0;
		right: 0;
		top: 50%;
		bottom: 50%;
	}

	// Menu Icon
	// -------------------------------------------------------------------------------
	${MenuIcon} {
		// Grid settings
		justify-self: end;
		align-self: center;
		
		z-index: ${ZIndexSettings.menuButton};
	}

	// Tracker
	// -------------------------------------------------------------------------------
	${Tracker} {
		justify-self: start;
	}

	// Artwork Carousel
	// -------------------------------------------------------------------------------
	${ArtworkCarousel} {
		z-index: ${ZIndexSettings.artwork};
	}

	// Magnetic
	// -------------------------------------------------------------------------------
	${Magnetic} {
		z-index: ${ZIndexSettings.nextPrev};
		
		${media.down('medium')`
			svg {
				transform: rotate(-90deg);
			}
		`};
		
		&.prev {
			// Grid settings
			grid-area: prev;
		}
		
		&.next {
			// Grid settings
			grid-area: next;
		}
	}

	// Navigation
	// -------------------------------------------------------------------------------
	${Navigation} {
		// Grid settings
		grid-row-start: 1;
		grid-row-end: 7;
		grid-column: full;
		
		z-index: ${ZIndexSettings.navigation};
		${media.up('large')`
			grid-row-end: 7;
		`}
	}
	
	// Artwork Overview
	// -------------------------------------------------------------------------------	
	${ArtworkOverview} {
		// Grid settings 
		grid-row-start: 1;
		grid-row-end: 7;
		grid-column: 1/6;
		
		
		padding-top: ${multiplyBase(15)};
		padding-left: ${multiplyBase(40)};
		padding-right: ${multiplyBase(40)};
		z-index: ${ZIndexSettings.artworkOverview};
		
		&:after {
			content:"";
			height: ${multiplyBase(15)};
		}
	}
	
	// About Me
	// -------------------------------------------------------------------------------
	${AboutMe} {
		// Grid settings
		grid-area: artwork-carousel;
	}
	
	// Overview Icon
	// -------------------------------------------------------------------------------
	${OverviewIcon} {
		// Grid settings
		grid-column: 4;
		grid-row: 5;
		justify-self: end;
		
		z-index: ${ZIndexSettings.overviewButton};
	}

	// Background
	// -------------------------------------------------------------------------------
	${Background} {
		// Grid settings
		grid-row-start: 1;
		grid-row-end: 8;
		grid-column: full;
		
		z-index: ${ZIndexSettings.background};
	
		${media.up('large')`
			grid-row-start: 1;
			grid-row-end: 7;
			grid-column: full;
		`};
	}
`;
