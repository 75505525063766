import styled, {css, keyframes} from "styled-components";
// Models
import {BackgroundStatus, IBackgroundProps} from "./background.model";

export const backgroundLeaveAnimation = keyframes`
	100% {
		opacity: 0;
	}
`;

export const backgroundEnterAnimation = keyframes`
	100% {
		opacity: 1;
	}
`;

export const Background = styled.div<IBackgroundProps>`
	${props => css`
		background: linear-gradient(328deg, ${props.to} 0%, ${props.from} 100%);
	`}
	
	${props => props.status === BackgroundStatus.ENTER && css`
		opacity: 0;
		animation: ${backgroundEnterAnimation} 2s ease-in-out forwards;
	`};
	
	${props => props.status === BackgroundStatus.LEAVE && css`
		opacity: 1;
		animation: ${backgroundLeaveAnimation} 2s ease-in-out forwards;
	`};
`;
