import styled, {css} from "styled-components";
// Animation
import {overviewIconInitAnimation} from "./animations/overview-icon.animations";
// Settings
import colors from "../../styles/colors/colors";
import {hoverFocus} from "../../styles/mixins/hover-focus";
import {multiplyBase} from '../../styles/mixins/calculate';
import { animationSettings } from '../../styles/settings/animation.settings';

export interface OverviewIconStyles {
	height: number;
	width: number;
}

export const OverviewIcon = styled.svg<OverviewIconStyles>`
	// Grid position
	grid-area: overview-icon;
	
	width: ${multiplyBase(8)};
	height: ${multiplyBase(8)};
	stroke: ${colors.icon.regular.default};
	transition: transform 0.2s cubic-bezier(0.23, 1, 0.32, 1),
	stroke 0.6s cubic-bezier(0.23, 1, 0.32, 1);
	
	${hoverFocus(css`
		&:hover {
			transform: scale(0.95);
			stroke: ${colors.icon.regular.hover};
		}
	`)};
	
	rect {
		fill: none;
		width: 0;
		height: 0;
		animation: ${overviewIconInitAnimation} ${animationSettings.speed.slowest}s forwards;
		
		&:nth-child(1) {
			// (32 / 2) + 4 || (16 / 2) + 4
			transform-origin: 20px 12px;
			transform: rotate(180deg);
		}
		&:nth-child(2) {
			transform-origin: 20px 40px;
			transform: rotate(180deg) scaleY(-1);
		}
		&:nth-child(3) {
		transform-origin: 0px 12px;
		transform: scaleY(-1);
		}
		&:nth-child(4) {
		}
	}
`;
