import React, {useEffect, useState} from 'react';
// Components
import {DribbbleIcon} from './components/DribbbleIcon/DribbbleIcon';
import {SocietySixIcon} from './components/SocietySixIcon/SocietySixIcon';
import {InstagramIcon} from './components/InstagramIcon/InstagramIcon';
// Models
import {IArtworkContentModel, IArtworkModel, pageCarouselOrientation} from '../../artwork-carousel.models';
// Styles
import * as S from './artwork-content.styles';
// Animation
import {
	currentSocialMediaAnimation,
	currentTitleAnimation,
	previousSocialMediaAnimation,
	previousTitleAnimation,
	setArtworkContentIndex
} from './animations/artwork-content.animation';

export interface IArtworkProps extends IArtworkContentModel {
	artworks: IArtworkModel[];
	currentArtworkIndex: number;
	orientation: pageCarouselOrientation;
}

const getPreviousArtworkIndex = (currentArtworkIndex: number,
                                 orientation: pageCarouselOrientation,
                                 maxArtworksIndex: number): number => {
	if (orientation === pageCarouselOrientation.NEXT) {
		if (currentArtworkIndex === maxArtworksIndex) {
			return maxArtworksIndex - 1;
		} else if (currentArtworkIndex === 0) {
			return maxArtworksIndex;
		} else {
			return currentArtworkIndex - 1;
		}
	}

	if (orientation === pageCarouselOrientation.PREV) {
		if (currentArtworkIndex === maxArtworksIndex) {
			return 0;
		} else if (currentArtworkIndex === 0) {
			return 1;
		} else {
			return currentArtworkIndex + 1;
		}
	}
	return 0;
};

export const ArtworkContent = (props: IArtworkProps) => {
	const {orientation, social, delay, artworks, currentArtworkIndex} = props;
	const {dribbbleLink, societySixLink, instagramLink} = social;

	const [isVisible, setVisibility] = useState(false);
	const [previousProps, setPreviousProps] = useState({
		artworkIndex: 0,
		orientation: pageCarouselOrientation.INTRO
	});


	useEffect(() => {
		let isMounted =  true;

		if (isMounted) {
			setTimeout(() => {
				setVisibility(true);
			}, delay ? delay * 1000 : 0);

			if (isVisible) {
				if (orientation === pageCarouselOrientation.INTRO) {
					currentTitleAnimation(currentArtworkIndex, orientation);
					currentSocialMediaAnimation(currentArtworkIndex, orientation);
					setArtworkContentIndex(currentArtworkIndex);
				}

				if (previousProps.artworkIndex !== currentArtworkIndex) {
					const maxArtworksIndex = artworks.length - 1;
					let previousArtworkIndex = getPreviousArtworkIndex(currentArtworkIndex, orientation, maxArtworksIndex);

					currentTitleAnimation(currentArtworkIndex, orientation);
					currentSocialMediaAnimation(currentArtworkIndex, orientation);

					previousTitleAnimation(previousArtworkIndex, orientation);
					previousSocialMediaAnimation(previousArtworkIndex, orientation);
					setArtworkContentIndex(currentArtworkIndex, previousArtworkIndex)

					setPreviousProps({ artworkIndex: currentArtworkIndex, orientation });
				}
			}
		}
		return () => {
			isMounted = false;
		}
	}, [artworks.length, currentArtworkIndex, delay, isVisible, orientation, previousProps.artworkIndex]);

	if (!isVisible) return null;

	return (
		<React.Fragment>
			{artworks.map((artwork: IArtworkModel, index: number) => (
				<S.ArtworkContent key={index}
				                  id={`artwork-content-${index}`}>
					<S.ArtworkTitle id={`artwork-title-${index}`}>
						<S.ArtworkTitleInner id={`artwork-title-inner-${index}`}>
							{artwork.title}
						</S.ArtworkTitleInner>
					</S.ArtworkTitle>
					<S.SocialMedia>
						{artwork.social.dribbbleLink && (
							<S.SocialMediaInner id={`dribbble-inner-${index}`}>
								<S.SocialMediaLink href={dribbbleLink} target="_blank">
									<DribbbleIcon/>
								</S.SocialMediaLink>
							</S.SocialMediaInner>
						)}
						{artwork.social.instagramLink && (
							<S.SocialMediaInner id={`instagram-inner-${index}`}>
								<S.SocialMediaLink href={instagramLink} target="_blank">
									<InstagramIcon/>
								</S.SocialMediaLink>
							</S.SocialMediaInner>
						)}
						{artwork.social.societySixLink && (
							<S.SocialMediaInner id={`society-six-inner-${index}`}>
								<S.SocialMediaLink href={societySixLink} target="_blank">
									<SocietySixIcon/>
								</S.SocialMediaLink>
							</S.SocialMediaInner>
						)}
					</S.SocialMedia>
				</S.ArtworkContent>
			))}
		</React.Fragment>
	);
};
