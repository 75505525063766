import styled from 'styled-components';
import colors from '../../styles/colors/colors';
import { lineAnimation } from './animations/logo.animations';
import { ILogoProps } from './logo.models';

export const Logo = styled.svg`
	display: block;
	fill-rule: evenodd;
	stroke-linejoin: round;
	stroke-miterlimit: 2;
	clip-rule: evenodd;
`;

export const ClipPath = styled.path`
  fill: none;
`;

export const PathLine = styled.path`
  stroke-linecap: round;
  fill: none;
  stroke: ${colors.icon.regular.default};
  stroke-width: 23px;
  
  &.skull_center-eye_bottom {
    stroke-dasharray: 280px;
    stroke-dashoffset: 280px;
    animation: ${lineAnimation(280, 280)} 1s linear forwards;
    animation-delay: 1.2s;
  }
  
  &.skull_center-nose {
    stroke-dasharray: 130px;
    stroke-dashoffset: 130px;
    animation: ${lineAnimation(130, 130)} 1s linear forwards;
    animation-delay: 1.2s;
  }
  
  &.skull_center-eyebrow-base {
    stroke-dasharray: 225px;
    stroke-dashoffset: 225px;
    animation: ${lineAnimation(225, 225)} 1s linear forwards;
    animation-delay: 1.2s;
  }
  
  &.skull_center-lip_top {
    stroke-dasharray: 240px;
    stroke-dashoffset: 240px;
    animation: ${lineAnimation(240, 240)} 1s linear forwards;
    animation-delay: 1.2s;
  }
  
  &.skull_center-lip_bottom {
    stroke-dasharray: 120px;
    stroke-dashoffset: 120px;
    animation: ${lineAnimation(120, 120)} 1s linear forwards;
    animation-delay: 1.2s;
  }
  
  &.skull_center-jaw {
    stroke-dasharray: 160px;
    stroke-dashoffset: 160px;
    animation: ${lineAnimation(160, 160)} 1s linear forwards;
    animation-delay: 1.2s;
  }
  
  &.skull_center-eyebrow-end {
    stroke-dasharray: 30px;
    stroke-dashoffset: 30px;
    animation: ${lineAnimation(30, 30)} .5s linear forwards;
    animation-delay: 2s;
  }
 
  &.skull_outer-base {
    animation: ${lineAnimation(1300, -1300)} 1.5s linear;
  }
  
  &.skull_outer-lip_top {
    stroke-dasharray: 100px;
    stroke-dashoffset: 100px;
    animation: ${lineAnimation(100, 100)} 1s linear forwards;
    animation-delay: 1.2s;
  }
  
  &.skull_outer-lip_bottom {
    stroke-dasharray: 100px;
    stroke-dashoffset: 100px;
    animation: ${lineAnimation(100, 100)} 1s linear forwards;
    animation-delay: 1.4s;
  }
`;
