import { keyframes } from 'styled-components';
import { multiplyBase } from '../../../../../styles/mixins/calculate';

export const artworkThumbnailAnimation =  keyframes`
  0% {
    opacity: 0;
    transform: translateX(-${multiplyBase(7)});
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;


export const artworkThumbnailRightAnimation =  keyframes`
  0% {
    opacity: 0;
    transform: translateX(${multiplyBase(7)});
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;
