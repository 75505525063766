import React, {Component} from 'react';
// Settings
import {TweenMax} from 'gsap';
// Models
import {artworkClassname, IArtworkModel, pageCarouselOrientation} from '../../artwork-carousel.models';
// Animations
import {
	artworkInitAnimation,
	artworkNextAnimation,
	artworkPrevAnimation,
} from './animations/artwork.animation';
// Styles
import * as S from './artwork.styles';


interface IArtworkProps {
	imageName: string;
	artworks: IArtworkModel[];
	orientation: pageCarouselOrientation;
	className?: artworkClassname;
}

export class Artwork extends Component<IArtworkProps> {
	private polygonMaskClass = 'polygon-mask';

	componentDidMount() {
		// Set initial state of the current mask
		TweenMax.set(`.${this.polygonMaskClass}`, {
			attr: {
				points: '0 0, 0 0, 0 2400, 0 2400',
			},
		});
		setTimeout(() => {
			artworkInitAnimation(this.props.imageName);
			}, 1000);
	}

	componentDidUpdate(prevProps: Readonly<IArtworkProps>) {
		const previousArtwork = prevProps.imageName || this.props.imageName;
		if (previousArtwork === this.props.imageName) return;

		if (this.props.orientation === pageCarouselOrientation.NEXT) {
			artworkNextAnimation(this.props.imageName, previousArtwork);
		} else if (this.props.orientation === pageCarouselOrientation.PREV) {
			artworkPrevAnimation(this.props.imageName, previousArtwork);
		}
	}

	render() {
		const {artworks} = this.props;
		// The image height dimension
		const iHeight = 1120;
		// The image width dimension
		const iWidth = 760;
		// ViewBox of the SVG and size of the image
		const viewBox = `0 0 ${iWidth} ${iHeight}`;
		// Image extension
		const imageExtension = '.png';
		return (
			<S.Artwork viewBox={viewBox}>
				{artworks.map((artwork: IArtworkModel, index: number) => (
					<g key={`artwork-index-${index}`} clipPath={`url(#clip-path-${artwork.imageName})`} className={artwork.className}>
						<clipPath clipPathUnits="userSpaceOnUse" id={`clip-path-${artwork.imageName}`}>
							<polygon className={this.polygonMaskClass} id={`${artwork.imageName}-mask`}/>
						</clipPath>
						<image
							id={artwork.imageName}
							width={iWidth}
							height={iHeight}
							xlinkHref={require(`../../../../../../assets/images/covers/cover-${artwork.imageName}${imageExtension}`)}
						/>
					</g>
				))}
			</S.Artwork>
		);
	}
}
