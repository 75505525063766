import styled from 'styled-components';
import fontType from '../../../../styles/typography/fonts';
import colors from '../../../../styles/colors/colors';

export const NotSupported = styled.div`
     position: absolute;
     display: flex;
     align-items: center;
     justify-content: center;
     flex-direction: column;
     top: 0;
     left: 0;
     width: 100vw;
     height: 100vh;
     background: ${colors.background.dark};
     color: ${colors.text.light};
     
    h1 {
    	font-family: ${fontType.title}
    }
    
    p {
		font-family: ${fontType.paragraph}
    }
`;
