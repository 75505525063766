import styled from 'styled-components';

export const Artwork = styled.svg`
	display: block;
	position: relative;
	
	g {
		position: absolute;
	}
`;
