import React from 'react';
import * as S from './app.styles';
import Portfolio from "./pages/Portfolio/Portfolio";
import ReactGA from 'react-ga';

ReactGA.initialize('UA-81191883-1');
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  return (
      <S.App>
        <Portfolio/>
      </S.App>
  );
}

export default App;
