import styled, {css} from 'styled-components';
// Animations
import {crossAnimationBottom, crossAnimationMiddle, crossAnimationTop} from './animations/menu-icon-cross.animation';
import {menuAnimationBottom, menuAnimationMiddle, menuAnimationTop} from './animations/menu-icon-menu.animation';
import {initAnimationBottom, initAnimationMiddle, initAnimationTop} from './animations/menu-icon-init.animations';
// Models
import {MenuStatus} from '../menu.models';
import {IMenuIconProps} from './menu-icon.models';
// Settings
import colors from '../../../styles/colors/colors';
import {hoverFocus} from '../../../styles/mixins/hover-focus';
import {animationSettings} from '../../../styles/settings/animation.settings';
import {multiplyBase} from '../../../styles/mixins/calculate';

export const MenuIcon = styled.svg<IMenuIconProps>`
	// Grid position
	grid-area: menu-button;
	
	width: ${multiplyBase(6)};
	height: ${multiplyBase(6)};
	transition: transform ${animationSettings.speed.fast}s cubic-bezier(0.23, 1, 0.32, 1);
	
	rect {
		transform-origin: 50% 50%;
		transition: fill ${animationSettings.speed.faster * 3}s cubic-bezier(0.23, 1, 0.32, 1);
	}

	${props => props.status === MenuStatus.ONINIT && css`
		${hoverFocus(css`
			&:hover {
			transform: scale(0.95);
				rect {
					fill: ${colors.icon.regular.hover};
				}
			}
		`)};

		rect {
			transform-origin: 4px 50%;
			fill: ${colors.icon.regular.default};
		}
		rect:nth-of-type(1) {
			transform: translateY(-16px) scaleX(0);
			animation: ${initAnimationTop} ${animationSettings.speed.medium}s cubic-bezier(0.23, 1, 0.32, 1) forwards;
		}
		rect:nth-of-type(2) {
			opacity: 0;
			animation: ${initAnimationMiddle} ${animationSettings.speed.medium}s cubic-bezier(0.23, 1, 0.32, 1) forwards;
			animation-delay: ${animationSettings.delay.shorter}s;
		}
		rect:nth-of-type(3) {
			transform: translateY(16px) scaleX(0);
			animation: ${initAnimationBottom} ${animationSettings.speed.medium}s cubic-bezier(0.23, 1, 0.32, 1) forwards;
			animation-delay: ${animationSettings.delay.shorter * 2}s;
		}
	`};

	${props => props.status === MenuStatus.ISOPEN && css`
		${hoverFocus(css`
			&:hover {
				transform: scale(0.95);
				rect {
					fill: ${colors.icon.regular.hover};
				}
			}
		`)};
		rect:nth-of-type(1) {
			transform: translateY(-16px);
			animation: ${menuAnimationTop} ${animationSettings.speed.slower}s cubic-bezier(0.23, 1, 0.32, 1);
		}
		rect:nth-of-type(2) {
			opacity: 1;
			animation: ${menuAnimationMiddle} ${animationSettings.speed.slower}s cubic-bezier(0.23, 1, 0.32, 1);
		}
		rect:nth-of-type(3) {
			transform-origin: 4px 50%;
			transform: translateY(16px) scaleX(0.5);
			animation: ${menuAnimationBottom} ${animationSettings.speed.slower}s cubic-bezier(0.23, 1, 0.32, 1);
		}
	`};

	${props => props.status === MenuStatus.ISCLOSED && css`
		rect {
			fill: ${colors.icon.light.default};
		}
		rect:nth-child(1) {
			animation: ${crossAnimationTop} ${animationSettings.speed.slower}s cubic-bezier(0.23, 1, 0.32, 1);
			transform: rotate(45deg);
		}
		rect:nth-child(2) {
			animation: ${crossAnimationMiddle} ${animationSettings.speed.slower}s cubic-bezier(0.23, 1, 0.32, 1);
			opacity: 0;
		}
		rect:nth-child(3) {
			animation: ${crossAnimationBottom} ${animationSettings.speed.slower}s cubic-bezier(0.23, 1, 0.32, 1);
			transform: rotate(-45deg) scaleX(1);
		}
		${hoverFocus(css`
			&:hover {
				transform: scale(0.95);
				opacity: 0.8;
				rect {
					fill: ${colors.icon.light.hover};
				}
			}
		`)}
	`};
`;
