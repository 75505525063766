import React, {Component} from "react";
// Animations
import {TweenMax} from "gsap";
// Models
import {IMagneticProps, IMagneticState} from './magnetic.models';
// Styles
import * as S from "./magnetic.styles";

export class Magnetic extends Component<IMagneticProps, IMagneticState> {
	private container: any;
	private innerContainer: any;

	constructor(props: IMagneticProps) {
		super(props);

		// reference to the DOM node
		this.container = null;
		this.state = {isVisible: false};
	}

	componentDidMount() {
		this.showElement();
	}

	componentDidUpdate(prevProps: Readonly<IMagneticProps>,
	                   prevState: Readonly<IMagneticState>) {
		if (!prevState.isVisible && this.state.isVisible) {
			this.init();
		}
	}

	/**
	 * Function that handles if element is shown directly or wait for a delay
	 * Used for intro animation
	 */
	showElement() {
		setTimeout(() => {
			this.setState({
				isVisible: true,
			});
		}, this.props.delay ? this.props.delay * 1000 : 0)
	}

	init() {
		TweenMax.set(this.innerContainer, {
			xPercent: -50,
			yPercent: -50,
			x: this.container.clientWidth / 2,
			y: this.container.clientHeight / 2
		});

		this.container.addEventListener('pointerenter', (e: any) => {
			TweenMax.to(this.innerContainer, 0.3, {});
			this.positionCircle(e);
		});

		this.container.addEventListener('pointerleave', () => {
			TweenMax.to(this.innerContainer, 0.3, {});
			this.resetPosition();
		});

		this.container.addEventListener('pointermove', (e: any) => {
			this.positionCircle(e);
		});
	}

	resetPosition() {
		const relX = this.container.clientWidth / 2;
		const relY = this.container.clientHeight / 2;
		TweenMax.to(this.innerContainer, 0.3, {x: relX, y: relY});
	}

	positionCircle(e: any) {
		const relX = e.pageX - this.container.offsetLeft;
		const relY = e.pageY - this.container.offsetTop;
		TweenMax.to(this.innerContainer, 0.3, {x: relX, y: relY});
	}

	render() {
		const {children, className, onClick} = this.props;
		const {isVisible} = this.state;
		if (!isVisible) return null;
		return (
			<S.Magnetic
				onClick={onClick}
				ref={div => (this.container = div)}
				className={className || ""}>
				<div className="inner-container" ref={e => (this.innerContainer = e)}>
					{children}
				</div>
			</S.Magnetic>
		);
	}
}
