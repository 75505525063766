import { FlattenSimpleInterpolation } from 'styled-components';
import media from "./media";

/**
 * Hover focus only shows hover and focus styles on devices bigger
 * than tablet. This is to prevent sticky hovers on touch devices.
 */
export const hoverFocus = (strings: FlattenSimpleInterpolation) =>
	media.up('large')`
		&:hover,
		&:focus {
			${strings};
		}
`;
