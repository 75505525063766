import React, { useEffect, useState } from 'react';

export const ReRenderer = (props: { children: React.ReactNode }) => {

  const [debounce, setDebounce] = useState(false);
  const [isVisible, setVisibility] = useState(true);

  useEffect(() => {
    window.addEventListener('resize', () => handleResize());
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  const handleResize = () => {
    if (!debounce) {
      handleDebounce();
      setVisibility(false);
      setVisibility(true);
    }
  };

  const handleDebounce = () => {
    setDebounce(true);

    setTimeout(() => {
      setDebounce(false);
    }, 1500);
  };

  return (
    <React.Fragment>
      {isVisible && props.children}
    </React.Fragment>
  );
};
