import styled from 'styled-components';
// Settings
import colors from '../../../../../../../../styles/colors/colors';
import {rem} from '../../../../../../../../styles/mixins/calculate';

export const DribbbleIcon = styled.svg`
	fill: none;
	stroke-width: ${rem(2)};
	stroke: ${colors.icon.regular.default};
	
	&:hover {
		stroke: ${colors.icon.regular.hover};
	}
`;
