import React from "react";
import {MenuStatus} from "../menu.models";

import * as S from "./navigation.styles";

export interface INavigationProps {
  status: MenuStatus;
  children: React.ReactNode;
}

export const Navigation = (props: INavigationProps) => {
  const { status, children } = props;
  const cnt = React.Children.count(children);

  return (
    <S.Navigation status={status} amountOfItems={cnt}>
      {children}
    </S.Navigation>
  );
};

export default Navigation;
