import styled from "styled-components";

export const Magnetic = styled.div`
	display: block;
	width: 100%;
	height: 100%;
	
	.inner-container {
		width: fit-content;
	}
`;
