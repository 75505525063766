import React, { useEffect, useState } from 'react';
// Models
import { ILoadingIconProps } from './loading-icon.models';
import { LoadingStatus } from '../../models/loading.models';
// Styles
import * as S from './loading-icon.styles';

export const LoadingIcon = (props: ILoadingIconProps) => {
  const { status } = props;
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {

    if (status === LoadingStatus.LOADED) {
      setTimeout(() => {
        setIsLoaded(true);
      }, 200);
    }
  }, [status]);

  return (
    <S.LoadingIcon>
      {!isLoaded && (
        <S.Loading status={status} viewBox="0 0 32 32">
          <rect x="0" y="0" height="32" width="4"/>
          <rect x="14" y="0" height="32" width="4"/>
          <rect x="28" y="0" height="32" width="4"/>
        </S.Loading>
      )}
      <S.Loaded status={status} viewBox="0 0 32 32">
        <rect x="0" y="0" height="32" width="4"/>
        <rect x="14" y="0" height="32" width="4"/>
        <rect x="28" y="0" height="32" width="4"/>
      </S.Loaded>
    </S.LoadingIcon>
  );
};
