import React  from 'react';
// Models
import { IArtworkModel } from '../ArtworkCarousel/artwork-carousel.models';
import { IArtworkOverviewProps } from './artwork-overview.model';
// Style
import * as S from './artwork-overview.styles';

export const ArtworkOverview = (props: IArtworkOverviewProps) => {
  const {artworks, clickArtwork} = props;
  let rowIndex = 1;

  const animationIndex = (arrayIndex: number) => {
    // Check if number is even
    if ((arrayIndex+1) % 2 === 0) {
      rowIndex = rowIndex+1;
      return rowIndex-1;
    }
    return rowIndex;
  };

  return (
    <S.ArtworkOverview>
      {artworks && (
        artworks.map((artwork: IArtworkModel, index: number) => (
          <S.ArtworkThumbnail key={index}
                              index={index}
                              delay={animationIndex(index)}
                              src={require(`../../../../assets/images/thumbnails/${artwork.imageName}-thumbnail.png`)}
                              onClick={() => clickArtwork(artwork, index)}
                              alt={artwork.title} />
        ))
      )}
    </S.ArtworkOverview>
  );
};
