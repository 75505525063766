import {keyframes} from 'styled-components';
import {TimelineMax, TweenMax} from 'gsap';
import {animationSettings} from '../../../../../../../styles/settings/animation.settings';

export const outerCircleInitAnimation = keyframes`
  50% {
        stroke-dasharray: 250px;
  }
  100% {
      stroke-dasharray: 1400px;
      stroke-dashoffset: 0;
  }
`;

export const gradientAnimation = (fromColors: string, toColor: string) => {
	new TimelineMax()
		.staggerTo(
			'#gradient #starter',
			1,
			{
				stopColor: fromColors,
			},
			0,
			0
		)
		.staggerTo(
			'#gradient #stopper',
			1,
			{
				stopColor: toColor,
			},
			0,
			0
		);
};

export const animateMoveBackgroundImage = (image: string) => {
	const imageId = `#${image}`;
	new TimelineMax({repeat: -1})
		.set(imageId, {
			attr: {
				x: -200,
				y: 0,
			},
		})
		.to(imageId,
			20, {
				attr: {
					x: -200,
					y: 0,
				},
			}).to(imageId,
		20, {
			attr: {
				x: -200,
				y: 0,
			},
		});
};

export const innerCircleInitAnimation = (innerCircle: string) => {
	const innerCircleId = `#${innerCircle}`;
	TweenMax.fromTo(
		innerCircleId,
		animationSettings.speed.slow * 2,
		{scale: 0, transformOrigin: '120px 120px'},
		{scale: 1}
	);
};
